//
function bodyDeviceInit(device) {
  var data_device = document.querySelectorAll("[data-device]");
  for (var i = 0; i < data_device.length; i++) {
    // els[i].setAttribute("class", "publish_1");
    data_device[i].setAttribute("data-device", device);
  }
  // document.body.classList.add("js");
  document.body.setAttribute("id", "body");
  document.body.setAttribute("data-device", device);
  document.body.setAttribute("data-orientation", "");

  //? ------------------------------------------------------------
  //? Orientation screen
  // https://gist.github.com/darryl-snow/3825198

  var orientation;

  // Find matches
  var mql = window.matchMedia("(orientation: portrait)");

  // If there are matches, we're in portrait
  if (mql.matches) {
    // Portrait orientation
    orientation = "portrait";
  } else {
    // Landscape orientation
    orientation = "landscape";
  }
  toggleOrientation(orientation);

  // Add a media query change listener
  mql.addListener(function (m) {
    if (mql.matches) {
      // Portrait orientation
      orientation = "portrait";
    } else {
      // Landscape orientation
      orientation = "landscape";
    }
    toggleOrientation(orientation);
  });
  // console.log(window.orientation);
  // console.log(orientation);

  // Listen for orientation changes
  window.addEventListener(
    "orientationchange",
    function () {
      // Announce the new orientation number
      if (mql.matches) {
        // Portrait orientation
        data_orientation = "portrait";
      } else {
        // Landscape orientation
        data_orientation = "landscape";
      }
      // console.log(window.orientation);
      // console.log(orientation);
      toggleOrientation(orientation);
    },
    false
  );

  // Listen for resize changes
  window.addEventListener(
    "resize",
    function () {
      // Get screen size (inner/outerWidth, inner/outerHeight)
      if (mql.matches) {
        // Portrait orientation
        orientation = "portrait";
      } else {
        // Landscape orientation
        orientation = "landscape";
      }
      // console.log(window.orientation);
      // console.log(orientation);
      toggleOrientation(orientation);
    },
    false
  );

  //?

  function toggleOrientation(orientation) {
    var data_orientation = document.querySelectorAll("[data-orientation]");
    for (var i = 0; i < data_orientation.length; i++) {
      // els[i].setAttribute("class", "publish_1");
      data_orientation[i].setAttribute("data-orientation", orientation);
    }
  }

  //? ------------------------------------------------------------
}

window.bodyDeviceInit = bodyDeviceInit;
