//? ****************************************************************************
// sass, scss
require("../scss/setup.scss");

//? ****************************************************************************
// js

// https://www.jacklmoore.com/autosize/
// https://github.com/jackmoore/autosize
import autosize from "autosize";

document.addEventListener("DOMContentLoaded", function () {
  //   console.log("hola World!");
  // console.log(autosize);
  //   alert("hola World!");
  autosize(document.querySelectorAll("textarea"));
});

//? ****************************************************************************

//
import "./body-setup.js";
//
// import "./bootstrap.js";
// import "./bootstrap-carousel.js";
//
// import "./sticky-header.js";
